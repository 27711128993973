<template>
  <div class="analysis">
    <MyBreadcrumb></MyBreadcrumb>
    <div class="analysis-main">
      <div class="title" style="margin-top: 0;" v-if="groupId">{{ realname }}的学习分析</div>
      <div class="title" style="margin-top: 0;" v-else>{{ stuDetail.realname }}的学习分析</div>
      <el-table :data="tableData" border size="small" v-if="!groupId">
        <el-table-column label="章节完成率">
          <template v-slot="{ row }">
            <div>{{ row.finish_rate ? row.finish_rate + '%' : '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="刷题数量">
          <template v-slot="{ row }">
            <div>{{ row.train_count ? row.train_count : '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="刷题正确率">
          <template v-slot="{ row }">
            <div>{{ !!+row.right_count ? (row.right_count / row.train_count * 100).toFixed(2) + "%" : "-" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="刷题错误率">
          <template v-slot="{ row }">
            <div>{{ !!+row.train_count ? ((row.train_count - row.right_count) / row.train_count * 100).toFixed(2) + "%" : "-" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="平均答题时长">
          <template v-slot="{ row }">
            <div>{{ row.use_time ? util.secondsToMS(row.use_time) : '-' }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="title">章节学习统计</div>
      <div class="search-box">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <el-form-item label="筛选课程:" v-if="groupId">
            <el-select v-model="searchForm.course_id" placeholder="请选择课程筛选" size="small" @change="changeCourse">
              <el-option v-for="item in courseList" :key="item.course_id" :label="item?.course?.name" :value="item.course_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="筛选周课表:">
            <el-select v-model="searchForm.task_id" placeholder="请选择周任务筛选" size="small" @change="getChartData()">
              <el-option v-for="item in taskList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-table style="margin-bottom: 20px;" v-for="item in tableData2" :key="item.id+new Date().getTime()" :style="{height:item.isFold ? '40px' : 'auto'}" :data="item.children"
                default-expand-all
                row-key="id"
                :border="false"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                size="small" >
        <el-table-column :label="item.node.name" prop="">
          <template slot="header">
            <div class="table-title">
              <div>{{ item.node.name }}</div>
              <div :class="item.isFold?'el-icon-caret-bottom':'el-icon-caret-top'" @click="foldTable(item)"></div>
            </div>
          </template>
          <el-table-column label="节" prop="node.name"></el-table-column>
          <el-table-column label="课件完成">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.ppt_status === "1" ? "已完成" : "未完成") : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="视频完成">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.video_status === "1" ? "已完成" : "未完成") : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="实操手册">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.enchiridion_status === "1" ? "已完成" : "未完成") : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="试题练习数量/总数">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.train_count || 0) : '' }}<span v-if="row.stat">/</span>{{ row.stat ? row.data_count : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="试题正确率">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (!!+row.stat.right_count ? (row.stat.right_count / row.stat.train_count * 100).toFixed(2) + "%" : "-") : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="刷题总时长">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.use_time ? util.secondsToMS(row.stat.use_time) : '-') : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="章节完成率">
            <template v-slot="{ row }">
              <div>{{ row.stat ? (row.stat.finish_rate ? row.stat.finish_rate + "%" : '-') : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="{row}">
              <el-button type="primary" size="small" @click="answerSheet(row)" v-if="!row.children || !row.children.length">答题卡</el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- <div class="charts">
        <div class="left">
          <div class="title">知识点掌握分析：</div>
          <div class="schedule">
            <div class="schedule-item" v-for="item in 10" :key="item">
              <div class=schedule-title>服务器组</div>
              <div class="schedule-main">
                <el-progress :percentage="50" :stroke-width="12"></el-progress>
                <div>掌握程度：80%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">错题本分析：</div>
          <div class="wrongquestion" ref="wrongChart"></div>
        </div>
      </div> -->
      <div class="title">近期练习数量对比</div>
      <div class="empty" v-if="!recentData.length">
        <img src="../../assets/images//new_icon//empty.png" alt="">
        <div>暂无数据</div>
      </div>
      <div class="recentChart" v-else ref="recentChart"></div>
    </div>
  </div>
</template>

<script>
import util from "@/utils/tools.js";
export default {
  name: "StuAnalysisDetail",
  data() {
    return {
      util,
      // 课程id
      courseId: "",
      // 任务id
      taskId: "",
      // 学习分析
      tableData: [],
      // 周任务列表
      taskList: [],
      searchForm: {
        task_id: "-1",
        course_id:'',
      },
      // 章节分析
      tableData2: [],
      // 近期练习图表数据
      recentData: [],
      myChart2: null,
      stuDetail: {},
      groupId:'',
      realname:'',
      courseList:[],
    }
  },
  created() {
    this.courseId = this.$route.query.courseId || "";
    this.taskId = this.$route.query.taskId || "-1";
    this.searchForm.task_id = this.$route.query.taskId || "-1";
    this.stuDetail = this.$route.query.stuDetail ? JSON.parse(this.$route.query.stuDetail) : {}
    this.tableData = [this.stuDetail];
    this.groupId = this.$route.query.groupId || '';
    this.realname = this.$route.query.realname || '';


    if(this.groupId){
      this.getCourseList().then(()=>{
        this.getTaskList();
        this.getChartData();
      });
    }else{
      this.getTaskList();
      this.getChartData();
    }
  },
  methods: {
    // 获取课程列表
    getCourseList(){
      const { searchForm }=this;
      return new Promise((resolve,reject)=>{
        this.api.course.courseGroupList({
          status:"0",
          user_type:"1",
          group_id:this.groupId
        }).then(res=>{
          if(res.list.length===0){
            return reject();
          }
          this.courseList=res.list||[];
          searchForm.course_id=this.courseList[0].course_id
          resolve();
        }).catch(e=>{
          reject(e);
        });
      });
    },
    changeCourse() {
      const { searchForm }=this;
      searchForm.task_id="-1";
      this.getTaskList();
      this.getChartData()
    },
    // 获取周任务
    getTaskList() {
      const { courseId } = this;
      this.api.course.courseTaskList({
        course_id: this.searchForm.course_id || courseId
      }).then(res => {
        res.list.unshift({
          name: "全部",
          id: "-1"
        });
        this.taskList = res.list || [];
      });
    },
    // 获取图表数据
    getChartData() {
      const { courseId, searchForm,stuDetail } = this;
      this.api.course.stuPersonStatistics({
        course_id: searchForm.course_id || courseId,
        task_id: searchForm.task_id,
        user_id: stuDetail.user_id
      }).then(res => {
        console.log("学生个人数据", res);
        res.map_node_stat_tree?.forEach((item,index)=>{
          if(index===0){
            item.isFold=false;
          }else{
            item.isFold=true;
          }
        });
        this.tableData2=res.map_node_stat_tree||[];
        this.recentData = res.stat_user_day_stat || [];
        this.setRecentChartData();
      });
    },
    foldTable(item){
      item.isFold=!item.isFold;
      console.log(item.id);

    },
    setRecentChartData() {
      const { recentData } = this;
      if(recentData.length===0){
        this?.myChart2.discope();
        this.myChart2=null;
        return;
      }
      this.$nextTick(()=>{
        if (!this.myChart2) {
          this.myChart2 = this.$echarts.init(this.$refs.recentChart);
        }
        const option = {
          // 提示
          tooltip: {
            trigger: 'axis'
          },
          // 布局
          grid: {
            left: '20px',
            right: '20px',
            bottom: '20px',
            top: "20px",
            containLabel: true
          },
          // x轴
          xAxis: {
            type: 'category',
            data: recentData.reduce((pre, cur) => {
              pre.push(cur.date);
              return pre;
            }, [])
          },
          // y轴
          yAxis: {
            type: 'value',
            minInterval: 1
          },
          // 数据
          series: [
            {
              name: '练习数量',
              type: 'line',
              stack: 'Total',
              data: recentData.reduce((pre, cur) => {
                pre.push(cur.train_count);
                return pre;
              }, [])
            },
          ]
        }
        this.myChart2.setOption(option);
      });


    },
    // 答题卡
    answerSheet(row){
      // TODO 跳转答题卡
      const { stuDetail }=this;
      this.$router.push({
        path:"/teacher/course/interactExam",
        query:{
          generateId:row.node.id, //节点id
          courseId:row.stat.course_id,
          userId:stuDetail.user_id,
          isResult:true,
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.analysis {
  padding: 20px;

  .analysis-main {
    background-color: white;
    border-radius: 6px;
    padding: 20px;
  }

  .title {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  .search-box {
    margin-bottom: 10px;
  }

  .table-title{
    display: flex;
    justify-content: space-between;
    .el-icon-caret-bottom{
      font-size: 20px;
      cursor: pointer;
    }
    .el-icon-caret-top{
      font-size: 20px;
      cursor: pointer;
    }
  }

  .charts {
    display: flex;

    .left,
    .right {
      width: 50%;
    }

    .left {
      .schedule {
        height: 400px;
        overflow-y: scroll;
        padding: 20px;

        .schedule-item {
          margin-bottom: 12px;

          .schedule-title {
            font-size: 14px;
            margin-bottom: 5px;
          }

          .schedule-main {
            display: flex;

            .el-progress {
              flex-grow: 1;
            }
          }
        }
      }

    }

    .right {
      .wrongquestion {
        padding: 20px;
        padding-bottom: 0;
        height: 400px;
      }
    }
  }

  .recentChart {
    height: 400px;
  }
  .empty{
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      height: 180px;
    }
  }
}
</style>
